import { Layout, Menu } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { SiderItems } from "../../../utils/constants/common";

const SideBar = () => {
  const { Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleNavClick = (e) => {
    console.log(e);
    navigate(`/${e.key}`);
  };

  return (
    <Sider
      breakpoint="xl"
      collapsedWidth="0"
      width={280}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <Menu
        theme="dark"
        className="side-menu"
        onClick={handleNavClick}
        defaultSelectedKeys={1}
        mode="inline"
        items={SiderItems}
      />
    </Sider>
  );
};

export default SideBar;
