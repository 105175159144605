import { AiOutlineExperiment } from "react-icons/ai";
import { BiBookAdd, BiSupport } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import { GoRepo } from "react-icons/go";
import { MdOutlineAccountBox, MdOutlineDashboard, MdSupervisorAccount } from "react-icons/md";
import Cloud from "../../assets/img/labrepoimages/Cloud.jpg"
import Devops from "../../assets/img/labrepoimages/Devops.jpg"
import Foren from "../../assets/img/labrepoimages/Foren.jpg"
import info from "../../assets/img/labrepoimages/info.jpg"
import Malware from "../../assets/img/labrepoimages/Malware.jpg"
import Mobile from "../../assets/img/labrepoimages/Mobile.jpg"
import Network from "../../assets/img/labrepoimages/Network.jpg"
import ReverseEng from "../../assets/img/labrepoimages/ReverseEng.jpg"
import Web from "../../assets/img/labrepoimages/Web.jpg"
import Notfound from "../../assets/img/labrepoimages/Notfound.jpg"




export const GlobalLabels = {
    // Public Route Names
    HOME: 'Home',
    ABOUT_US: 'About us',
    LAB_REPO: 'Lab Repository',
    HELP_DESK: 'Help desk',
    LOGIN: 'Login',
    REGISTRATION: 'Registration',
    REMEMBER: "Remember",
    ME: "Me",
    FORGOT: "Forgot",
    WITH: "With",
    GOOGLE: "Google",
    APPLE: "Apple",
    // Admin Panel Names
    DASHBOARD: 'Dashboard',
    USER: "User",
    STAFF: "Staff",
    STUDENT: "Student",
    LABS: "Labs",
    ADDLAB: "Add Lab",
    SUPPORT: "Support",
    // Table Col Names
    NAME: "Name",
    EMAIL: "Email",
    PHONE: "Phone",
    ADDRESS: "Address",
    TITLE: "Title",
    ISSUE: "Issue",
    CATEGORY: "Category",
    ROLE: "Role",
    // Lab Form Labels
    LAB_TITLE: "Lab Title",
    LAB_OWNER: "Lab Owner",
    THUMBNAIL: "Thumbnail",
    UPLOAD: "Upload",
    NO_OF_MACH: "No of Machines / Instances",
    MACH_TYPE: "Machine / Instance type",
    LAB_INSTRUCTIONS: "Lab Instructions",
    DESCPRITION: "Lab Description",
    // Reg form Labels
    FIRST: "First",
    LAST: "Last",
    PASSWORD: "Password",
    CONFIRM: "Confirm",
    LOCATION: "Location",
    COUNTRY: "Country",
    STATE: "State",
    CITY: "City",
    OTP: "Otp",
    RESET: "Reset",
    SUBMIT: "Submit"
}

export const labRepositaries = [
    {
        id: '001',
        name: 'networkSecurity',
        path: 'network-security',
        label: 'Network Security',
        img: Network,
        noOfLabs: 0,
    }, {
        id: '002',
        name: 'webApplicationSecurity',
        path: 'web-application-security',
        label: 'Web Application Security',
        img: Web,
        noOfLabs: 0,
    }, {
        id: '003',
        name: 'mobileSecurity',
        path: 'mobile-security',
        label: 'Mobile Security',
        img: Mobile,
        noOfLabs: 0,
    }, {
        id: '004',
        name: 'wirelessSecurity',
        path: 'wireless-security',
        label: 'Wireless Security',
        img: Notfound,
        noOfLabs: 0,
    }, {
        id: '005',
        name: 'dataSecurity',
        path: 'data-security',
        label: ' Data Security',
        img: Notfound,
        noOfLabs: 0,
    }, {
        id: '006',
        name: 'informationSecurity',
        path: 'information-security',
        label: 'Information Security',
        img: info,

        noOfLabs: 0,
    }, {
        id: '007',
        name: 'cloudSecurity',
        path: 'cloud-security',
        label: 'Cloud Security',
        img: Cloud,
        noOfLabs: 0,
    }, {
        id: '008',
        name: 'DevSecOps',
        path: 'DevSecOps',
        label: 'Dev Sec Ops',
        img: Devops,
        noOfLabs: 0,
    }, {
        id: '009',
        name: 'malwareAnalysis',
        path: 'malware-analysis',
        label: 'Malware Analysis',
        img: Malware,
        noOfLabs: 0,
    }, {
        id: '010',
        name: 'reverseEngineering',
        path: 'reverse-engineering',
        label: 'Reverse Engineering',
        img: ReverseEng,
        noOfLabs: 0,
    }, {
        id: '011',
        name: 'vulnerabilityAssesmentPenetrationTesting',
        path: 'vulnerability-assesment-penetration-testing',
        label: 'Vulnerability Assesment Penetration Testing',
        img: Notfound,
        noOfLabs: 0,
    }, {
        id: '012',
        name: 'digitalForensics',
        path: 'digital-forensics',
        label: 'Digital Forensics',
        img: Foren,
        noOfLabs: 0,
    }, {
        id: '013',
        name: 'ioTSecurity',
        path: 'ioT-Security',
        label: 'IoT Security',
        img: Notfound,
        noOfLabs: 0,
    }
]
// SIDE NAV BAR ITEMS
export const SiderItems = [
    {
        label: GlobalLabels.DASHBOARD,
        key: "admin/dashboard",
        icon: <MdOutlineDashboard className="sidebarIcon" />,
    },
    {
        label: GlobalLabels.USER,
        key: 1,
        icon: <FaRegUserCircle className="sidebarIcon" />,
        children: [
            {
                type: "group",
                children: [
                    {
                        label: GlobalLabels.STAFF,
                        key: "admin/staff",
                        icon: <MdOutlineAccountBox className="sidebarIcon" />,
                    },
                    {
                        label: GlobalLabels.STUDENT,
                        key: "admin/student",
                        icon: <MdSupervisorAccount className="sidebarIcon" />,
                    },
                ],
            },
        ],
    },
    {
        label: GlobalLabels.LABS,
        key: 2,
        icon: <AiOutlineExperiment className="sidebarIcon" />,
        children: [
            {
                type: "group",
                children: [
                    {
                        label: GlobalLabels.ADDLAB,
                        key: "admin/add-lab",
                        icon: <BiBookAdd className="sidebarIcon" />,
                    },
                    {
                        label: GlobalLabels.LAB_REPO,
                        key: "lab-repository",
                        icon: <GoRepo className="sidebarIcon" />,
                        children: labRepositaries.map((lab) => {
                            return {
                                label: lab.label,
                                key: `admin/lab-repository/${lab.path}`,
                            };
                        }),
                    },
                ],
            },
        ],
    },
    {
        label: GlobalLabels.SUPPORT,
        key: "admin/support",
        icon: <BiSupport className="sidebarIcon" />,
    },
];
// TOP NAV ITEMS
export const TopNavItems = [
    {
        key: "/",
        label: GlobalLabels.HOME,
    },
    {
        key: "/help-desk",
        label: GlobalLabels.HELP_DESK,
    },
    {
        key: "/lab-repository",
        label: GlobalLabels.LAB_REPO,
    },
];

export const LabCheckBoxOptions = ["Docker", "VM Ware", "Pnet"];