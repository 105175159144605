import {
  AppleFilled,
  GoogleOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Input,
  Checkbox,
  Button,
  Space,
  Divider,
  Typography,
  Form,
} from "antd";
import LightthemeLOGO from "../../assets/img/logo/SkillsDA Logo with Text - Blue Colour.png";
import { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import { GlobalLabels } from "../../utils/constants/common";

const Login = () => {
  const { Text } = Typography;
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <>
      <div className="flex align-items-center dir-column bg-hammer-blue credentials-container main-container justify-start">
        <img
          src={LightthemeLOGO}
          className="logo-container"
          alt="logo"
          width={200}
          height={50}
        />
        <Space direction="vertical" align="center" wrap>
          <Form
            name="login-form"
            initialValues={{
              remember: true,
            }}
            // onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Text className="flex label-bottom-m label-size-weight color-skills-da">
                {GlobalLabels.EMAIL}
              </Text>
              <Input
                className="input-fields basic-input-width"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Text className="flex label-bottom-m label-size-weight color-skills-da">
                {GlobalLabels.PASSWORD}
              </Text>
              <Input.Password
                className="input-fields basic-input-width"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: () => setPasswordVisible(!passwordVisible),
                }}
              />
            </Form.Item>

            <div className="flex justify-space-between margin-bottom">
              <div>
                <Checkbox id="remember-me" value={"remember-me"}></Checkbox>
                <label
                  htmlFor="remember-me"
                  className="margin-left pointer font-size-large"
                >
                  {`${GlobalLabels.REMEMBER} ${GlobalLabels.ME}`}
                </label>
              </div>
              <a href="/#">
                <span className="link font-size-large">{`${GlobalLabels.FORGOT} ${GlobalLabels.PASSWORD}?`}</span>
              </a>
            </div>

            <Form.Item>
              <Button
                className="basic-input-width"
                htmlType="submit"
                type="primary"
                size={"large"}
              >
                {GlobalLabels.LOGIN}
              </Button>
            </Form.Item>

            <Divider orientation="center" plain>
              {" "}
              OR{" "}
            </Divider>

            <Form.Item>
              <Button
                className="basic-input-width"
                type="default"
                icon={<FcGoogle />}
                size={"large"}
              >
                {`${
                  GlobalLabels.LOGIN
                } ${GlobalLabels.WITH.toLocaleLowerCase()} ${
                  GlobalLabels.GOOGLE
                }`}
              </Button>
            </Form.Item>

            <Form.Item name={"login-btn"}>
              <Button
                className="basic-input-width"
                type="default"
                icon={<FaApple />}
                size={"large"}
              >
                {`${
                  GlobalLabels.LOGIN
                } ${GlobalLabels.WITH.toLocaleLowerCase()} ${
                  GlobalLabels.APPLE
                }`}
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </div>
    </>
  );
};

export default Login;
