import {
  HomeOutlined,
  MenuOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Col,
  ConfigProvider,
  Divider,
  Menu,
  Modal,
  Row,
  Tabs,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { BiLogInCircle } from "react-icons/bi";
import { FaEdit, FaSignInAlt } from "react-icons/fa";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import darkLogo from "../../assets/img/logo/SkillsDA Logo with Text - White Colour.png";
import { GlobalLabels, TopNavItems } from "../../utils/constants/common";
import Login from "../login";

const TopNavBar = ({ isLoggedIn }, ...rest) => {
  const position = ["left", "right"];
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [defaultActiveKey, setDefaultActiveKey] = useState("/");
  const location = useLocation();
  const { pathname } = location;

  const OperationsSlot = {
    left: <img src={darkLogo} className="homeLogo" alt="logoimg" onClick={() => navigate(`/`)} />,
    right: isLoggedIn ? (
      <>
        <Badge dot>
          <Avatar
            size={"large"}
            alt="Avatar"
            shape="square"
            icon={<UserOutlined />}
            style={{ backgroundColor: "#87d068", cursor: "pointer" }}
          />
        </Badge>
      </>
    ) : (
      <div className="menubarRightButtons">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#0b4a72",
            },
          }}
        >
          <Button
            type="primary"
            // shape="round"
            onClick={() => setLoginModal(!loginModal)}
          >
            <div className="modelContents">
              <BiLogInCircle size={"20"} />
              <span>{GlobalLabels.LOGIN}</span>
            </div>
          </Button>
        </ConfigProvider>


        <ConfigProvider
          theme={{
            token: {
              colorPrimaryHover: "#0b4a72",
            },
          }}
        >
          <Button
            type="default"
            // shape="round"
            onClick={() => navigate("/register")}
          >
            <div className="modelContents">
              <span>{GlobalLabels.REGISTRATION}</span>
              <HiOutlinePencilSquare size={"20"} />
            </div>
          </Button>
        </ConfigProvider>

      </div>
    ),
  };

  const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  // console.log("defaultActiveKey", defaultActiveKey);

  useEffect(() => {
    const tabRoutes = ["/", "/help-desk", "/lab-repository"];
    if (!isLoggedIn && !tabRoutes.includes(pathname)) {
      setDefaultActiveKey("0");
    } else {
      setDefaultActiveKey(pathname);
    }
  }, [pathname, defaultActiveKey, isLoggedIn]);

  const tabOnChange = (key) => {
    setDefaultActiveKey(key);
    navigate(`${key}`);
  };

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const renderMenu = () => {
    return (
      <Menu onClick={handleMenuClick} className="menu">
        <div style={{ marginLeft: "35px", marginTop: "24px" }}>
          <Menu.Item key="home" icon={<HomeOutlined />}>
            <NavLink to="/">{GlobalLabels.HOME}</NavLink>
          </Menu.Item>
          <Menu.Item key="help-desk" icon={<UserOutlined />}>
            <NavLink to="/help-desk">{GlobalLabels.HELP_DESK}</NavLink>
          </Menu.Item>
          <Menu.Item key="lab-repository" icon={<SettingOutlined />}>
            <NavLink to="/lab-repository">{GlobalLabels.LAB_REPO}</NavLink>
          </Menu.Item>
        </div>
        <Divider />
        <div style={{ marginLeft: "35px" }}>
          <Menu.Item
            key="login"
            icon={<FaSignInAlt />}
            onClick={() => setLoginModal(!loginModal)}
          >
            {GlobalLabels.LOGIN}
          </Menu.Item>
          <Menu.Item
            key="register"
            icon={<FaEdit />}
            onClick={() => navigate("/register")}
          >
            {GlobalLabels.REGISTRATION}
          </Menu.Item>
        </div>
        <Divider />
        <Menu.Item>footer</Menu.Item>
      </Menu>
    );
  };

  const updateSize = () => {
    const windowSize = Math.max(window.innerWidth);
    if (windowSize >= 768) {
      setIsMenuOpen(false);
    }
  };

  const menubarImage = () => {
    navigate(`/`)
    // setIsMenuOpen(false)
  }

  useEffect(() => {
    window.addEventListener("resize", updateSize);
  }, []);

  return (
    <React.Fragment>
      <Row justify={"center"} align="middle">
        <Col xs={isLoggedIn ? 22 : 0} md={22}>
          <Tabs
            tabBarGutter={55}
            defaultActiveKey={"/"}
            activeKey={defaultActiveKey}
            tabBarExtraContent={slot}
            items={!isLoggedIn && TopNavItems}
            onChange={tabOnChange}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={isLoggedIn ? 0 : 22} md={0}>
          <div style={{ marginTop: "8px" }}>
            <MenuOutlined
              onClick={handleMenuClick}
              style={{ color: "white", fontSize: "20px", marginTop: "8px" }}
            />
          </div>
        </Col>

        <Col xs={isLoggedIn ? 0 : 2} md={0}>
          <img src={darkLogo} className="homeLogo" alt="logoimg" onClick={menubarImage} />
        </Col>
      </Row>

      {/* RESPONSIVE HAMBURG MENU OPEN */}
      {isMenuOpen && renderMenu()}
      {/* Login Modal */}
      <Modal
        centered
        open={loginModal}
        onCancel={() => setLoginModal(false)}
        footer={null}
      >
        <Login />
      </Modal>
    </React.Fragment>
  );
};

export default TopNavBar;
