import { lazy } from "react";

export const appRoutes = [
  {
    name: "Home",
    path: "/",
    component: lazy(() => import("../../containers/home")),
  },
  {
    name: "Help Desk",
    path: "/help-desk",
    component: lazy(() => import("../../containers/help-desk")),
  },
  {
    name: "About Us",
    path: "/about-us",
    component: lazy(() => import("../../containers/about-us")),
  },
  {
    name: "Lap Repository",
    path: "/lab-repository",
    component: lazy(() => import("../../containers/lab-repository")),
  },
  {
    name: "Admin Login",
    path: "/admin/login",
    component: lazy(() => import("../../containers/admin-panel/admin-login")),
  },
  {
    name: "Dashboard",
    path: "/admin/dashboard",
    component: lazy(() => import("../../containers/admin-panel/dashboard")),
  },
  {
    name: "Add Lab",
    path: "/admin/add-lab",
    component: lazy(() => import("../../containers/admin-panel/add-lab")),
  },
  {
    name: "Staff",
    path: "/admin/staff",
    component: lazy(() => import("../../containers/admin-panel/staff")),
  },
  {
    name: "Add Staff",
    path: "/admin/staff/add-staff",
    component: lazy(() =>
      import("../../containers/admin-panel/staff/add-staff")
    ),
  },
  {
    name: "Student",
    path: "/admin/student",
    component: lazy(() => import("../../containers/admin-panel/student")),
  },
  {
    name: "Add Student",
    path: "/admin/student/add-student",
    component: lazy(() =>
      import("../../containers/admin-panel/student/add-student")
    ),
  },
  {
    name: "Support ",
    path: "/admin/support",
    component: lazy(() => import("../../containers/support")),
  },
];

export const privateRoutes = [
  {
    name: "Dashboard",
    path: "/admin/dashboard",
    component: lazy(() => import("../../containers/admin-panel/dashboard")),
  },
  {
    name: "Add Lab",
    path: "/admin/add-lab",
    component: lazy(() => import("../../containers/admin-panel/add-lab")),
  },
  {
    name: "labs",
    path: "/admin/lab-repository/:lab",
    component: lazy(() => import("../../containers/lab-repository/lab")),
  },
  {
    name: "Staff",
    path: "/admin/staff",
    component: lazy(() => import("../../containers/admin-panel/staff")),
  },
  {
    name: "Add Staff",
    path: "/admin/staff/add-staff",
    component: lazy(() =>
      import("../../containers/admin-panel/staff/add-staff")
    ),
  },
  {
    name: "Student",
    path: "/admin/student",
    component: lazy(() => import("../../containers/admin-panel/student")),
  },
  {
    name: "Add Student",
    path: "/admin/student/add-student",
    component: lazy(() =>
      import("../../containers/admin-panel/student/add-student")
    ),
  },
  {
    name: "Support ",
    path: "/admin/support",
    component: lazy(() => import("../../containers/support")),
  },
  {
    name: "Page 404",
    path: "*",
    component: lazy(() => import("../../components/404")),
  },
];

export const publicRoutes = [
  {
    name: "Home",
    path: "/",
    component: lazy(() => import("../../containers/home")),
  },
  {
    name: "Help Desk",
    path: "/help-desk",
    component: lazy(() => import("../../containers/help-desk")),
  },
  {
    name: "About Us",
    path: "/about-us",
    component: lazy(() => import("../../containers/about-us")),
  },
  {
    name: "Lap Repository",
    path: "/lab-repository",
    component: lazy(() => import("../../containers/lab-repository")),
  },
  {
    name: "Lap Repository",
    path: "/lab-repository/:lab",
    component: lazy(() => import("../../containers/lab-repository/lab")),
  },
  {
    name: "Register",
    path: "/register",
    component: lazy(() => import("../../containers/register")),
  },
  {
    name: "Admin Login",
    path: "/admin/login",
    component: lazy(() => import("../../containers/admin-panel/admin-login")),
  },
  {
    name: "Page 404",
    path: "*",
    component: lazy(() => import("../../components/404")),
  },
];
