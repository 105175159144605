import { Breadcrumb, Layout } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SideBar from "../containers/admin-panel/side-bar";
import TopNavBar from "../containers/top-nav-bar";
import { privateRoutes, publicRoutes } from "../utils/constants/app-routes";

const AppRoutes = () => {
  const isLoggedIn = true; // MAKE IT TRUE TO SEE PRIVATE AND FALSE TO PUBLIC ROUTES
  // const windowSize = Math.max(window.innerWidth);

  return (
    <>
      <Router>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            minHeight: "75px",
            justifyContent: "center",
            shadowColor: "transparent",
            shadowRadius: 0,
            shadowOffset: {
              height: 0,
            },
          }}
        >
          <TopNavBar isLoggedIn={isLoggedIn} />
        </Header>

        <Layout hasSider>
          {/* SIDE BAR */}
          {isLoggedIn && <SideBar />}

          {/* PRIVATE & PUBLIC ROUTES */}
          <Layout>
            <Content style={{ minHeight: "707.5px" }}>
              <Breadcrumb></Breadcrumb>
              <Routes>
                {isLoggedIn ? (
                  <React.Fragment>
                    {privateRoutes.map((route, index) => {
                      let Component = route.component;
                      return (
                        <Route
                          key={`route-${index}`}
                          path={route.path}
                          element={
                            <Suspense fallback={<div>Loading....</div>}>
                              <Component />
                            </Suspense>
                          }
                        />
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {publicRoutes.map((route, index) => {
                      let Component = route.component;
                      return (
                        <Route
                          key={`route-${index}`}
                          path={route.path}
                          element={
                            <Suspense fallback={<div>Loading....</div>}>
                              <Component />
                            </Suspense>
                          }
                        />
                      );
                    })}
                  </React.Fragment>
                )}
              </Routes>
            </Content>

            <Footer style={{ textAlign: "center", backgroundColor: "#cacaca" }}>
              Skills DA ©
            </Footer>
          </Layout>
        </Layout>
      </Router>
    </>
  );
};

export default AppRoutes;
